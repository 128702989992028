// @ngInject
function AcpEsignBottomSheetComponentCtrl(
  $scope,
  acpCoreDispatcher,
  acpTermsModel,
  $mdBottomSheet,
  acpUserModel,
  acpCardownerDomainModel
) {
  function init() {
    $scope.$onValue(acpCoreDispatcher.terms.accepted.success, onTermsAccepted);
    $scope.$onValue(
      acpCoreDispatcher.terms.accepted.error,
      onTermsAcceptedFailed
    );

    acpUserModel.get();

    $scope.profileFields = ['email'];

    $scope.$onValue(acpCoreDispatcher.profile.user.updated, onAccountUpdate);

    $scope.esign = {
      accepted: false
    };
  }

  function onAccountUpdate(account) {
    $scope.account = account;

    $scope.email = account.primary_card_owner.email;
  }

  $scope.canSubmit = function() {
    return $scope.email && $scope.esign.accepted;
  };

  $scope.cancel = function() {
    $mdBottomSheet.cancel();
  };

  $scope.$onValue(acpCoreDispatcher.profile.user.edit.success, function() {
    acpUserModel.get();
  });

  function onProfileEditSuccess() {
    acpCoreDispatcher.profile.user.edit.success.emit(true);
    acceptTerms();
  }

  function onProfileEditError() {
    // TODO
  }

  function acceptTerms() {
    if ($scope.esign.accepted === true) {
      acpTermsModel.acceptTerms('esign');
    }
  }

  function onTermsAccepted() {
    acpCoreDispatcher.esign.accepted.emit();
    if (!$scope.embedded) {
      $mdBottomSheet.hide();
    }
  }

  function onTermsAcceptedFailed() {
    $scope.error = true;
  }

  $scope.save = function() {
    if (!$scope.canSubmit()) {
      return;
    }
    acpCoreDispatcher.scrollTo.top.emit();
    $scope.email = $scope.esignForm['email'].$modelValue;

    if ($scope.esignForm.email.$dirty) {
      acpCardownerDomainModel
        .edit($scope.account.primary_card_owner.id, { email: $scope.email })
        .then(onProfileEditSuccess, onProfileEditError);
    } else {
      acceptTerms();
    }
  };

  init();
}

export default AcpEsignBottomSheetComponentCtrl;
