import acpPaywallEsignDialogTemplate from './templates/acp-paywall-esign-dialog.html';
import { acpEsignOptOutDialogTemplate } from 'components/esign-opt-out-dialog';
// @ngInject
function AcpEsignComponentCtrl(
  $location,
  $scope,
  nsUtil,
  acpAnalytics,
  acpTermsModel,
  acpCoreDispatcher,
  acpDialog,
  $state
) {
  var type = 'esign',
    isEsignViewed = false,
    eSignTermsDialogName = 'acpPaywallEsignDialog',
    eSignOptOutDialogName = 'acpEsignOptOutDialog';

  var TYPES = {
    ACTIVATION: 'activation',
    PREFERENCES: 'preferences',
    PAYWALL: 'paywall',
    INTERSTITIAL: 'interstitial'
  };

  // This is used to whitelist instances where we don't
  // need to show an opt out notice because the user would
  // not have been enrolled in e-sign yet if they hit these
  // esign types.
  var optOutNoticeWhitelist = [
    TYPES.ACTIVATION,
    TYPES.PAYWALL,
    TYPES.INTERSTITIAL
  ];

  // For these types, the user can't proceed unless they accept
  var impassibleTypes = ['sendMoney', 'ingo', 'points', 'wu'];

  $scope.error = false;
  $scope.busy = false;

  function trackEsign(action) {
    if (
      $scope.type === 'activation' &&
      $location.path() === '/activate/additional-card'
    ) {
      acpAnalytics.sendEvent(
        'feature_enrollments',
        action,
        'activation_postlogin_esign'
      );
    } else {
      acpAnalytics.sendEvent(
        'feature_enrollments',
        action,
        'activation_prelogin_esign'
      );
    }
  }

  function init() {
    if ($scope.prefetch) {
      acpTermsModel.getEsign().then(onEsign);
    }

    trackEsign('viewed');

    $scope.permissions = {};
    $scope.$tiePermissions('permissions', ['disallowEsignOptOut']);
  }

  // Currently paywall is handing all responses
  function done() {
    $scope.busy = false;
  }

  function onEsign(data) {
    $scope.accepted = data.accepted ? 'true' : 'false';
  }

  function onTermsAccepted(response) {
    if (response.type === type) {
      acpCoreDispatcher.esign.accepted.emit();
      done();
    }
  }

  function onTermsAcceptedFailed(response) {
    if (response.type === type) {
      done();
      $scope.error = true;
    }
  }

  function onTermsDeclined(response) {
    if (response.type === type) {
      acpCoreDispatcher.esign.declined.emit();
      done();
    }
  }

  function onTermsDeclinedFailed(response) {
    if (response.type === type) {
      done();
      $scope.error = true;
    }
  }

  $scope.save = function(accepted, $event) {
    if ($scope.shouldDisableButton()) {
      return;
    }

    acpCoreDispatcher.scrollTo.top.emit();
    $scope.busy = true;

    if (accepted === 'true') {
      acpTermsModel.acceptTerms(type);

      // Necessary to ensure Activation's esign preference
      // saves in Manage Account > Profile preference (for example)
      $scope.accepted = 'true';
      trackEsign('opted_in');

      acpCoreDispatcher.activation.featureEnrollment.esign.complete.emit();
    } else {
      if (optOutNoticeWhitelist.indexOf($scope.type) !== -1) {
        acpTermsModel.declineTerms(type);
        trackEsign('opted_out');
      } else {
        // If user is opting out in preferences, we need to display a warning
        // telling the user several account actions will occur.
        acpCoreDispatcher.dialog.show.emit({
          name: eSignOptOutDialogName,
          preload: import(/* webpackChunkName: "acp.component.esign-opt-out-dialog" */ 'components/esign-opt-out-dialog'),
          template: acpEsignOptOutDialogTemplate,
          targetEvent: $event,
          onComplete: function() {
            acpDialog.get(eSignOptOutDialogName).then(function() {
              acpTermsModel.declineTerms(type);
            }, done);
          }
        });
      }

      acpCoreDispatcher.activation.featureEnrollment.esign.skipped.emit();
    }
  };

  $scope.shouldDisableButton = function() {
    if (!nsUtil.isDefined($scope.accepted)) {
      return true;
    }

    if (nsUtil.isDefined($scope.paywallType)) {
      if (
        impassibleTypes.indexOf($scope.paywallType) > -1 &&
        $scope.accepted !== 'true'
      ) {
        return true;
      }
    }
    return false;
  };

  $scope.showActivationText = function() {
    return $scope.type === 'activation';
  };

  $scope.shouldShowManageProfileTitle = function() {
    /* eslint-disable-next-line es5/no-es6-methods */
    return $state.includes('manage.account');
  };

  $scope.shouldShowGenericExceptionText = function() {
    return nsUtil.isUndefined($scope.paywallType);
  };

  $scope.shouldShowMoveMoneyExceptionText = function() {
    return $scope.paywallType === 'sendMoney' && $scope.accepted === 'false';
  };

  $scope.shouldShowIngoExceptionText = function() {
    return $scope.paywallType === 'ingo' && $scope.accepted === 'false';
  };

  $scope.shouldShowPointsExceptionText = function() {
    return $scope.paywallType === 'points' && $scope.accepted === 'false';
  };

  $scope.shouldShowWuSendMoneyExceptionText = function() {
    return $scope.paywallType === 'wu' && $scope.accepted === 'false';
  };

  $scope.shouldShowWuLoadMoneyExceptionText = function() {
    return $scope.paywallType === 'wuLoadMoney' && $scope.accepted === 'false';
  };

  $scope.showEsignTerms = function($evt) {
    $scope.acceptTermsError = false;
    isEsignViewed = true;
    acpCoreDispatcher.dialog.show.emit({
      name: eSignTermsDialogName,
      controller: 'AcpEsignPaywallDialogComponentCtrl',
      template: acpPaywallEsignDialogTemplate,
      ok: 'Close!',
      targetEvent: $evt
    });
  };

  $scope.isEsignViewed = function() {
    return isEsignViewed;
  };

  $scope.close = function() {
    acpCoreDispatcher.dialog.hide.emit({
      name: eSignTermsDialogName
    });
  };

  init();

  $scope.$onValue(acpCoreDispatcher.terms.accepted.success, onTermsAccepted);
  $scope.$onValue(
    acpCoreDispatcher.terms.accepted.error,
    onTermsAcceptedFailed
  );

  $scope.$onValue(acpCoreDispatcher.terms.declined.success, onTermsDeclined);
  $scope.$onValue(
    acpCoreDispatcher.terms.declined.error,
    onTermsDeclinedFailed
  );
}

export default AcpEsignComponentCtrl;
