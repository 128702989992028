import acpEsignTemplate from './templates/acp-esign.html';
// @ngInject
function acpEsignDirective() {
  return {
    restrict: 'E',
    scope: {
      paywallType: '@',
      prefetch: '@',
      type: '@'
    },
    controller: 'AcpEsignComponentCtrl',
    template: acpEsignTemplate
  };
}

export default acpEsignDirective;
