import esignBottomSheetContent from './esign-bottom-sheet-content.yml';
import esignContent from './esign-content.yml';
import acpEsignCtrl from './acp-esign-ctrl';
import acpEsignBottomSheetCtrl from './acp-esign-bottom-sheet-ctrl';
import acpEsignBottomSheetDirective from './acp-esign-bottom-sheet-directive';
import acpEsignInterstitialDirective from './acp-esign-interstitial-directive';
import acpEsignDialogInterstitialDirective from './acp-esign-dialog-interstitial-directive';
import acpEsignDirective from './acp-esign-directive';
import acpComponentCardownerDomain from 'components/cardowner-domain';
import acpComponentContactCs from 'components/contact-cs';
import { acpPointsProgramDisplayNameModule } from 'components/points-program-display-name';
import acpDialog from 'shared/angularjs/services/acp-dialog';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';
import acpUnsafeHtmlFilter from 'shared/angularjs/filters/acp-unsafe-html';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.esign
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-esign></acp-esign>
 * ```
 *
 * ```js
 * angular.module('acp.component.esign.example', [
 *   require('acp.component.esign').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.esign', [
    acpCore.name,
    acpDialog.name,
    acpUnsafeHtmlFilter.name,
    acpComponentContactCs.name,
    acpComponentCardownerDomain.name,
    acpPointsProgramDisplayNameModule.name
  ])
  .directive('acpEsign', acpEsignDirective)
  .directive('acpEsignInterstitial', acpEsignInterstitialDirective)
  .directive('acpEsignDialogInterstitial', acpEsignDialogInterstitialDirective)
  .directive('acpEsignBottomSheet', acpEsignBottomSheetDirective)
  .controller('AcpEsignBottomSheetCtrl', acpEsignBottomSheetCtrl)
  .controller('AcpEsignComponentCtrl', acpEsignCtrl)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put('components/esign', esignContent);
      contentSectionCache.put(
        'components/esign-bottom-sheet',
        esignBottomSheetContent
      );
    }
  );

export default component;

// @bazel-dep: components/esign-opt-out-dialog
