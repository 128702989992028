import acpEsignInterstitialTemplate from './templates/acp-esign-interstitial.html';
// @ngInject
function acpEsignInterstitialDirective() {
  return {
    restrict: 'E',
    scope: {
      paywallType: '@',
      prefetch: '@',
      type: '@',
      acpInterstitialCtrl: '=ctrl'
    },
    controller: 'AcpEsignComponentCtrl',
    template: acpEsignInterstitialTemplate
  };
}

export default acpEsignInterstitialDirective;
