import acpEsignBottomSheetContentTemplate from './templates/acp-esign-bottom-sheet-content.html';
// This is a reusable directive allowing us to display and complete the esign paywall as an md-bottom-sheet. As a reusable
// bottom-sheet it does not supply its own <md-bottom-sheet> and will need to be wrapped in one for proper usage. The
// advantage of this approach is that the bottom-sheet can be used within another bottom-sheet.

// @ngInject
function acpEsignBottomSheetDirective() {
  return {
    restrict: 'E',
    scope: {
      esignDescription: '=', // Explains to the user why you are asking for esign
      embedded: '@' // Set to true if you are handling the bottom-sheet lifecycle yourself, by default the bottom-sheet will close as soon as you complete esign
    },
    template: acpEsignBottomSheetContentTemplate,
    controller: 'AcpEsignBottomSheetCtrl'
  };
}

export default acpEsignBottomSheetDirective;
