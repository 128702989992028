import acpEsignDialogInterstitialTemplate from './templates/acp-esign-dialog-interstitial.html';
// @ngInject
function acpEsignDialogInterstitialDirective() {
  return {
    restrict: 'E',
    scope: {
      paywallType: '@',
      prefetch: '@',
      type: '@'
    },
    controller: 'AcpEsignComponentCtrl',
    template: acpEsignDialogInterstitialTemplate
  };
}

export default acpEsignDialogInterstitialDirective;
